// custom variables
$white: #fff;
$silver: #ebebeb;
$light-grey: #cccccc;
$grey: #9a9a9a;
$dark-grey: #666666;
$blue-primary: #0000a0;
$blue-secondary: #3c5aff;
$blue-tertiary: #0a0a82;
$purple-primary: #973887;
$purple-secondary: darken($purple-primary, 5%);
$claret-primary: #820037;
$red: #db2828;
$orange: #eb5a46;
$green: #005a3c;
$sea-green: #4fa48d;
$off-black: #0b0c10;
$highlight-yellow: #eaab00;
$pale-yellow: #f0c756;
$pale-blue: #f1f7fd;
$bright-green: #00a532;
$pale-green: #f5faf7;
$teal: #21cbde;
$hover-grey: #e5e5e5;
$hover-blue: #e5f2fc;

$tab-active: #f2f2f2;
$tab-hover: darken($tab-active, 5%);
$tab-subitem-hover: $hover-blue;

$gallery-placeholder: #d9ebfa;
$gallery-hover: rgba(0, 0, 0, 0.4);

$programme-primary-border: 1px solid $off-black;
$programme-secondary-border: 1px solid #b2c5d4;
$programme-content-background: $white;
$programme-content-hover: #e0ebf4;
$programme-upcoming-background: #e6ffe0;
$programme-upcoming-hover: darken($programme-upcoming-background, 2%);

$field-border-colour: #6e6f81;
$field-border: 1px solid $field-border-colour;
$field-border-focus: 1px solid $blue-primary;

$splash-title-background: #0077d7;

$calendar-bg: #f4f4f4;
$calendar-item-colour: #366092;
$calendar-summary-selected: #f5f6fa;
$calendar-summary-selected-border: #8db4e2;
$calendar-summary-grey: #c9c9d2;

$calendar-policy-blue-bg: #e5ebf1;
$calendar-commercial-yellow-bg: #f9f2f6;
$calendar-concept-red-bg: #f7ede7;
$calendar-staff-green-bg: #e5f0ed;
$calendar-fixture-bg: #efefef;

$calendar-internal-policy-event: rgb(0, 0, 160);
$calendar-internal-policy-concept: rgb(60, 90, 255);
$calendar-external-policy-event: rgb(0, 90, 60);
$calendar-external-policy-concept: rgb(0, 165, 50);
$calendar-commercial-event: rgb(190, 86, 0);
$calendar-commercial-concept: rgb(255, 135, 0);
$calendar-other-event: rgb(130, 0, 55);
$calendar-other-concept: rgb(240, 0, 0);
$calendar-adhoc: rgb(0, 0, 0);

$conversion-likelihood-colours: (
  pct25: #f3ae0b,
  pct50: #acaf20,
  pct75: #77af2f,
  pct85: #77af2f,
  pct100: #00b050,
);

$profile-default-background-color: #003f7233;
$warning-red: #eb5a46;
$warning-red-light: #fef9f9;
$toggle-colour: $bright-green;

$grey-text: $calendar-summary-grey;

// font weightings
$light-weight: 300;
$regular-weight: 400;
$semibold-weight: 600;
$bold-weight: 700;
$extrabold-weight: 800;

// screen breakpoints
$mobile-small: 420px;
$mobile-max: 639px;
$tablet-min: 640px;
$tablet-max: 1067px;
$desktop-min: 1068px;
$desktop-wide: 1400px;
$desktop-hd: 1920px;

// cinnamon overrides
$font-url: "https://use.typekit.net/rvd6mrj.css";
$font-name: "elza";
$font-family: $font-name, Arial, "Helvetica Neue", Helvetica, sans-serif;
$font-size: 14px;
$text-color: rgba(0, 0, 0, 0.87);
$background-color: #f3f4fb;
$loadable-background-color: $background-color;

$primary-color: $blue-primary;
$primary-color-hover: $blue-secondary;
$primary-color-focus: $blue-secondary;
$primary-color-down: darken($blue-secondary, 8%);
$primary-color-active: darken($blue-secondary, 10%);

$positive-color: $blue-primary;
$positive-color-hover: darken($positive-color, 5%);
$positive-color-focus: darken($positive-color, 5%);
$positive-color-down: darken($positive-color, 8%);
$positive-color-active: darken($positive-color, 10%);

$negative-color: $grey;
$negative-color-hover: darken($negative-color, 5%);
$negative-color-focus: darken($negative-color, 5%);
$negative-color-down: darken($negative-color, 8%);
$negative-color-active: darken($negative-color, 10%);

$green-hover: $bright-green;
$green-focus: $bright-green;
$green-down: darken($bright-green, 8%);
$green-active: darken($bright-green, 10%);

$is-favourite-icon-color: #f3ae0b;

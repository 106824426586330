.app-nav-menu {
  color: $blue-primary;
  background-color: $white;
  padding: 0 10em;

  @media screen and (max-width: $desktop-wide) {
    padding: 0 1em;
  }

  > .ui.menu {
    min-height: 72px;
    align-items: flex-start;
    position: relative;
    @media screen and (max-width: $mobile-max) {
      flex-wrap: wrap;
    }

    .app-nav-wrapper {
      display: flex;
      margin-right: auto;
    }

    .app-nav-account {
      display: flex;
    }

    .app-nav-items {
      display: flex;
      height: 72px;
    }

    .item {
      color: $blue-primary;
      font-size: 1.15em;

      &.active {
        background: $tab-active;
      }

      &:hover {
        background: $tab-hover;
        color: $blue-primary;
      }
    }

    .alerts-nav-item,
    .account-nav-item,
    .global-search-nav-item {
      height: 72px;
      padding-left: 0.75em;
      padding-right: 0.75em;

      &:hover {
        background: none;
      }
    }

    .alerts-nav-item {
      > i {
        font-size: 1.7em;
        margin-right: 0;
      }

      > .label {
        position: absolute;
        top: 10px;
        right: 8px;
      }
    }

    .account-nav-item {
      padding-right: 0.25em;
      padding-top: 0;
      padding-bottom: 0;

      > i {
        font-size: 1.2em;
        margin-left: 0.25em;
        margin-right: 0;
      }
    }

    .cin.action-menu {
      > .nav-menu-item {
        height: 100%;
      }

      &.active > .nav-menu-item {
        background: $tab-hover;
      }

      > .ui.menu {
        height: auto;
        display: none;
        background-color: $white;
        box-shadow: 2px 2px 5px 0px $light-grey;

        > .nav-menu-item {
          display: flex;
          align-items: center;

          &:not(.active) {
            background-color: $white !important;
          }

          > img {
            max-height: 16px;
            width: 16px;
          }

          > span {
            margin-top: 2px;
            margin-bottom: -2px;
          }
        }

        > .nav-menu-item:hover {
          background-color: $tab-subitem-hover !important;
        }
      }
    }

    .global-search-nav-item {
      padding-left: 0.25em;

      .ui.input {
        > input {
          padding-top: 0.75em;
          border-radius: 20px 0 0 20px;
          border-color: $blue-primary;
          border-right: none;
          color: $off-black;
          &:focus {
            border-right: none;
          }
        }
        > .ui.button {
          border-radius: 0 20px 20px 0;
          background-color: $blue-primary;
          border: solid 1px $blue-primary;
          border-left: none;
          .icon {
            color: $white;
            padding-right: 0.5em;
            font-size: 1.1em;
          }
        }
        > .ui.label {
          border-radius: 0;
          border-top: solid 1px $blue-primary;
          border-bottom: solid 1px $blue-primary;
          padding-left: 0.5em;
          padding-right: 0.5em;
          cursor: pointer;
          .icon {
            margin: 0 !important;
          }
          &.hide i {
            visibility: hidden;
          }
        }
      }
    }
  }

  a.logo {
    display: flex;
    margin-right: 4em;
    height: 72px;

    @media screen and (max-width: $desktop-wide) {
      margin-right: 2em;
    }

    @media screen and (max-width: $tablet-max) {
      margin-right: 0em;
    }

    img {
      min-width: 170px;
      height: 52px;
      margin: auto;
    }
  }

  @media screen and (max-width: $mobile-small) {
    > .ui.menu .account-nav-item {
      position: absolute;
      top: 0;
      right: 0;
    }

    a.logo {
      margin-right: 0;

      img {
        margin-left: 0;
      }
    }
  }

  .ui.dropdown.account-menu {
    font-size: 1rem;
    height: 72px;

    > .menu > .cin.action-menu-item:hover {
      background-color: $tab-subitem-hover !important;
    }
  }

  .alerts-menu {
    font-size: 1rem;
    height: 72px;

    .scrollable-content {
      max-height: calc(100vh - 150px);
      max-width: calc(100vw - 2rem);
      border-top: 1px solid $silver;
      overflow-x: hidden;
      min-height: 50px;
    }
  }
}

.app-breadcrumbs {
  background-color: $blue-primary;
  min-height: 8px;
}

.app-footer {
  margin-top: auto;
  flex-shrink: 0;

  .action-bar {
    color: $white;
    background-color: $blue-primary;
    min-height: 72px;
    margin-bottom: 0;
    padding: 0 10em;
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    .action-bar-item:not(:last-child) {
      margin: 0.5em 4em 0.5em 0;
    }

    @media screen and (max-width: $tablet-max) {
      padding: 0 1em;

      .action-bar-item:last-child {
        width: 100%;
      }
    }

    @media screen and (max-width: $mobile-small) {
      justify-content: center;

      .action-bar-item {
        width: 100%;
        margin-right: 0 !important;
        text-align: center;
      }
    }

    a {
      font-weight: normal;
      color: $white;
      &:hover {
        color: $silver;
      }
    }

    .social-icon {
      display: inline-block;
      border: 1px solid $white;
      border-radius: 50%;
      height: 36px;
      width: 36px;
      padding: 8px;
      margin: 5px 0 5px 10px;

      &:hover {
        border-color: $silver;
      }

      > .image {
        max-height: 18px;
        margin: auto;
      }
    }
  }
}

.tab-nav-menu > .ui.menu {
  display: flex;
  flex-wrap: wrap;

  .nav-menu-item {
    font-size: 1.2em;
    font-weight: bold;
    padding-left: 2px;

    &.active {
      background: none;
      color: $blue-primary;
    }

    &:hover {
      background: none;
      color: $blue-secondary;
    }
  }
}

.side-nav-menu.cin.nav-menu > .ui.menu {
  display: flex;
  flex-wrap: wrap;
  .nav-menu-header {
    font-size: 18px;
    font-weight: $semibold-weight;
    color: $off-black !important;
    padding-top: 0 !important;
    padding-left: 2px !important;
  }
  .nav-menu-item:not(.secondary) {
    font-size: 16px;
    padding: 0.9em 2px !important;

    &.active {
      background: none;
      color: $blue-primary;
      font-weight: $bold-weight;
      &:hover {
        background: none;
        color: $blue-secondary;
      }
      > span {
        position: relative;
        display: inline;
        &::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          background-color: $blue-primary;
          bottom: -4px;
          left: 0;
        }
      }
    }

    &:hover {
      background: none;
      color: $blue-secondary;
    }
  }
}

.burger {
  align-items: center;
  justify-content: center;
  display: flex;
  cursor: pointer;
}

.burger-side-panel.cin.side-panel {
  .close-panel {
    top: -0.25rem;
    right: -0.25rem;
  }
}

.burger-menu {
  margin-left: -1rem;
  margin-right: -1rem;
  flex-grow: 1;
  overflow: auto;
  .ui.vertical.menu {
    .nav-menu-item {
      font-size: 16px;
      background-color: transparent;
      color: $off-black;
      &.active {
        background-color: transparent;
        color: $blue-primary;
        font-weight: $bold-weight;
      }
    }

    .submenu {
      margin-left: 1rem;
      .nav-menu-header {
        font-weight: $semibold-weight;
        color: $off-black !important;
        margin-top: 0 !important;
        padding: 0.6em 1rem;
        font-size: 14px;
      }
      .nav-menu-item {
        font-size: 14px;
      }
    }
  }
}

.social-media-link {
  display: inline-block;
  height: 32px;
  margin-right: 0.5rem;
  > a > img {
    height: 100%;
    width: auto;
  }
  &.disabled {
    > a > img {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
}

.progress-nav {
  $circleSize: 24px;
  .progress-items {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .progress-item {
    display: flex;
    position: relative;

    .progress-num {
      flex-shrink: 0;
      width: $circleSize;
      height: $circleSize;
      background-color: $white;
      color: $text-color;
      margin-right: 1em;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 12px;
      z-index: 2;
      border: solid 1px $blue-primary;
      position: relative;
    }

    .progress-title {
      flex-grow: 1;
      z-index: 2;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      min-width: 0;
      line-height: $circleSize;
      @include DefineFont(16px, $semibold-weight);
    }

    &.completed {
      cursor: pointer;

      .progress-num {
        background-color: $blue-primary;
        color: $white;
        padding-top: 2px;
      }
    }

    &.selected {
      .progress-title {
        color: $blue-primary;
      }
      .progress-num::after {
        content: "";
        display: block;
        position: absolute;
        background-color: transparent;
        width: calc(#{$circleSize} + 4px);
        height: calc(#{$circleSize} + 4px);
        border-radius: $circleSize;
        border: solid 1px $blue-primary;
        top: -3px;
        left: -3px;
      }
    }

    &.finished {
      .progress-num {
        background-color: $green;
        border-color: $green;
        .icon {
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      &.selected {
        .progress-num::after {
          border-color: $green;
        }
        .progress-title {
          color: $green;
        }
      }
    }

    &.locked {
      .progress-num {
        background-color: $grey;
        color: $white;
        border-color: $grey;
      }
      &::before {
        background-color: $grey !important;
      }
    }

    &:not(:first-child):before {
      content: "";
      display: block;
      width: 2px;
      height: 100%;
      background-color: $blue-primary;
      position: absolute;
      top: -30%;
      left: calc(#{$circleSize} / 2 - 1px);
      z-index: 1;
    }

    & + .progress-item {
      padding-top: 1em;
    }
  }

  &.horizontal {
    overflow: hidden;
    .progress-items {
      display: flex;
      overflow: auto hidden;

      .progress-item {
        padding: 0.5em;
        & + .progress-item {
          margin-left: 1em;
        }
        &::before {
          display: none;
        }
      }
    }
  }
}

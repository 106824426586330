.ui.modal {
  > .header {
    border-bottom: 1px solid $blue-primary;
    color: $blue-primary;
  }

  > .actions {
    border-top: 1px solid $blue-primary;
  }
}

.ui.modal.form-modal {
  > .content {
    background-color: $background-color;

    .ui.dimmer {
      background-color: rgba($background-color, 0.8);
    }
  }
}

.ui.modal.table-modal {
  > .content {
    padding: 0.5em 1.5em;

    .ui.table {
      margin-left: -1.5em;
      margin-bottom: 0.5em;
      width: calc(100% + 3em);

      th:first-child,
      td:first-child {
        padding-left: 1.5em;
      }

      th:last-child,
      td:last-child {
        padding-right: 1.5em;
      }
    }

    .ui.dimmer + .ui.table {
      margin-top: 0;
    }

    .ui.dimmer + .ui.form,
    .ui.dimmer + .ui.form + .ui.table {
      margin-top: 0.5em;
    }
  }
}

.ui.modal.form-modal,
.ui.modal.table-modal {
  .close-modal {
    position: absolute;
    top: 1.25rem;
    right: 1.5rem;
    box-shadow: none;
    color: $negative-color !important;
    margin-right: 0;
    padding: 1px 0;
    font-size: 1.1em;

    &:hover {
      color: $negative-color-hover !important;
    }
  }
}

.ui.modal.image-lightbox {
  .icon {
    color: $grey;
    cursor: pointer;
    &:hover {
      color: $white;
    }
  }

  > .close {
    top: -1rem;
    font-size: 2rem;
  }

  > .header {
    min-height: 3rem;
  }

  .navigation {
    display: flex;
    align-items: center;
    justify-content: center;

    .current {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      width: 750px;
      max-width: 100%;
    }

    .icon {
      margin: 1rem;
    }
  }

  .image {
    border-radius: 5px;
    height: auto;
  }

  &.multiple .navigation {
    min-height: 500px;
  }
}

.ui.modal.video-player {
  .icon {
    color: $grey;
    cursor: pointer;
    &:hover {
      color: $white;
    }
  }

  > .close {
    top: -1rem;
    font-size: 2rem;
  }

  > .header {
    min-height: 3rem;
  }

  > .content {
    text-align: center;
    > .dimmable {
      display: inline-block;
    }
  }

  .react-player {
    background-color: black;
  }
}

.three-button-modal.ui.modal {
  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 1rem !important;
    .main-actions {
      .ui.button + .ui.button {
        margin-left: 1em;
      }
    }
  }
}

.resource-tile.cin.tile {
  padding: 0;
  border: 1px solid $light-grey;
  box-shadow: 2px 2px 5px 0px $light-grey;
  display: flex !important;
  align-items: flex-start;
  min-height: 5.8rem;
  font-size: 1rem;

  > .details {
    flex-grow: 1;
    padding: 0.75rem 1.25rem;
    display: flex;
    flex-wrap: wrap;

    > .ui.header {
      font-size: 1.15em;
      font-weight: normal;
      margin-bottom: 0.3em;

      > .sub.header {
        display: inline-block;
        font-size: 0.8em;

        > .info,
        > .status {
          margin-left: 1em;
        }
      }
    }

    > .actions {
      margin-left: auto;
      margin-bottom: 0.3em;
    }

    > .description {
      width: 100%;
      font-size: 0.85em;
    }
  }

  > .resource-link {
    height: 100%;
    min-width: 2.8rem;
    color: $white;
    background-color: $purple-primary;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: $purple-secondary;
    }

    > i.icon {
      height: 1.5em;
      margin-right: 0;
    }

    &.busy {
      background-color: $grey;
      cursor: progress;
    }
  }
}

.resource-tile-action.ui.label {
  margin-left: 2rem;
  padding: 0;
  font-size: 0.85rem;
  font-weight: bold;
  color: $blue-primary;
  cursor: pointer;

  &:hover {
    color: $blue-secondary;
  }
}

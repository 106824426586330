.ui.table {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid $blue-primary;
  border-radius: 0;

  &.inverted {
    background: $blue-tertiary;
  }

  thead th {
    background: initial;
    border-bottom: 1px solid $blue-primary;
  }

  td > .ui.dropdown > .icon.ellipsis {
    font-size: 1.2rem;
    margin: 0.1rem 0.5rem;
  }
}

.table-panel.ui.segment {
  padding: 1em 2em;
  &.no-border {
    border: 0;
    box-shadow: none;
  }

  @media screen and (max-width: $tablet-max) {
    padding: 1.2em 1em;
    overflow-x: auto;
  }

  .action-bar {
    align-items: center;
  }

  .ui.table {
    margin-left: -2em;
    width: calc(100% + 4em);
    th:first-child,
    td:first-child {
      padding-left: 2em;
    }

    th:last-child,
    td:last-child {
      padding-right: 2em;
    }
  }

  > *:last-child.action-bar {
    margin-bottom: -1em;
  }

  &.compact {
    padding: 1em;
    width: 100%;
    .ui.table {
      margin-left: -1em;
      width: calc(100% + 2em);
      th:first-child,
      td:first-child {
        padding-left: 1em;
      }

      th:last-child,
      td:last-child {
        padding-right: 1em;
      }
    }
  }
}

.table-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > .results-counter {
    font-size: 12px;
    min-width: 120px;
  }

  .ui.pagination.cin.page-number {
    border: none;
    box-shadow: none;

    .item {
      display: flex;
      justify-content: center;
      min-width: initial;
      padding: 0.5em;
      margin: 0.2em;

      &:before {
        background: none;
      }

      &:focus {
        outline: none;
      }
    }

    .item[type="prevItem"] > i {
      position: relative;
      margin-right: 5em;
      &:after {
        content: "Previous";
        position: absolute;
        left: 1.5em;
        font-family: $font-family;
      }
    }

    .item[type="nextItem"] > i {
      position: relative;
      margin-left: 3.5em;
      &:after {
        content: "Next";
        position: absolute;
        right: 1.5em;
        font-family: $font-family;
      }
    }
  }

  &.simple {
    .ui.pagination.cin.page-number {
      .item[type="pageItem"] {
        display: none;
      }
      .item[type="prevItem"] > i {
        margin-right: 4.5em;
      }
      .item[type="nextItem"] > i {
        margin-left: 3em;
      }
    }
  }
}

.selection-table {
  margin-top: 1.5em;
  height: 305px;
  overflow-y: auto;
  border: $field-border;
  border-radius: 4px;
  background-color: $white;
  .ui.table {
    margin-bottom: 0;
    tr > td {
      height: 3.6em;
    }
  }
  .load-more {
    min-height: 3.6em;
  }
}

.ui.modal .selection-table {
  background-color: $background-color;
  .ui.table {
    border-bottom: 1px solid $silver;
    thead th {
      background-color: $background-color;
      border-bottom: 1px solid $grey;
      position: sticky;
      top: 0;
    }
    tbody tr {
      background-color: $white;
    }
    td {
      padding-top: 0.6em;
      padding-bottom: 0.6em;
    }
  }
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -1rem;

  .image-gallery-thumb {
    position: relative;
    width: auto;
    height: 7rem;
    margin: 0 2rem 1rem 0;

    &.major {
      height: 9rem;
    }

    &.minor {
      height: 7rem;
    }

    > img {
      height: 100%;
    }

    > i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }

    &.selectable {
      cursor: pointer;
    }

    &.dimmable {
      > i {
        display: none;
        z-index: 2;
      }

      &:hover {
        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: $gallery-hover;
          z-index: 1;
        }

        > i {
          display: block;
        }
      }
    }
  }

  .image-gallery-add {
    width: 7rem;
    height: 7rem;
    margin: 0 2rem 1rem 0;
    background-color: $gallery-placeholder;
    border: 1px dashed $blue-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    > i {
      font-size: 2rem;
      color: $blue-primary;
      margin: 0.5rem 0 0 0;
    }

    &:hover {
      border-color: $blue-secondary;
      > i {
        color: $blue-secondary;
      }
    }
  }

  .image-gallery-placeholder {
    width: 7rem;
    height: 7rem;
    margin: 0 2rem 1rem 0;
  }
}

.accreditation-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: left;
  align-items: bottom;
  word-wrap: break-word;
  font-size: 1rem;
  padding: 0.5rem;
}

.accreditation-overlay-cover {
  margin-left: 24px;
  margin-right: 12px;
  margin-bottom: 0.5rem;
}

.accreditation-content {
  div,
  span,
  p,
  a,
  a:hover {
    color: $white !important;
  }
}

.image-container {
  position: relative;
  display: inline-block;
}

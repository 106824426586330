.floating-button.ui.segment {
  display: inline-flex;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0 0 0 1rem;
  box-shadow: 2px 2px 0px 0px $silver;

  > img {
    width: 12px;
    height: 12px;
  }

  &.large {
    padding: 0.65rem;
    > img {
      width: 18px;
      height: 18px;
    }
  }

  &:hover {
    background-color: $hover-blue;
  }
}

.ellipsis-button.ui.button {
  padding: 0.5em;
  > .icon {
    margin: 0 !important;
  }
}

.toggle-visibility-button {
  cursor: pointer;
  color: $blue-primary;
  display: flex;
  float: right;
}

.click-area {
  display: flex;
  background-color: $white;
  padding: 0.5em;
  border: dashed 1px $blue-primary;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $blue-primary;
}

.advanced-search-button {
  width: 38px;
  height: 38px;
  i.icon.search {
    width: 19px !important;
    height: 19px !important;
  }
}

@mixin DefineFont($size: $font-size, $weight: $regular-weight) {
  font-size: $size;
  font-weight: $weight;
}

@mixin LargeBoldText {
  @include DefineFont(23px, $bold-weight);
}

@mixin Regular16Text {
  @include DefineFont(16px);
}

@mixin LineClamp($maxLines: 2, $fallbackMaxHeight: 0) {
  $maxHeight: $fallbackMaxHeight;
  @if $maxHeight == 0 {
    $maxHeight: $maxLines * 1.5em;
  }
  // unoffical way to get ellipsis to work on 2 lines. works on all modern browsers (tested chrome/firefox/edge). fallback will display entire text
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $maxLines;
  line-clamp: $maxLines;
  -webkit-box-orient: vertical;
  max-height: $maxHeight; // emergancy limit for when -webkit-box is not supported (prevent all text being displayed)
}

@mixin ColourCheckbox($colour) {
  &.checked label:after {
    content: "";
    display: block;
    width: 11px;
    height: 11px;
    top: 3px;
    left: 3px;
    border-radius: 2px;
    background-color: $colour;
  }
}

@mixin StripedBackground($color) {
  background: repeating-linear-gradient(
    -45deg,
    white,
    white 2px,
    $color 2px,
    $color 10px
  );
}

.alert-list .alert-item:not(:last-child) {
  border-bottom: 1px solid $silver;
}

.alert-item {
  position: relative;
  font-size: 1rem;
  padding: 0.25em 1em 0.25em 2em !important;

  > .label {
    width: 40px;
  }

  > .content {
    margin: 0.6em 0 0 1em !important;
    min-width: 0;

    > .summary {
      margin-bottom: 0.5em !important;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    > .date {
      font-size: 0.8em !important;
      color: $dark-grey !important;
      text-transform: capitalize;
    }
  }

  &.outstanding > .label::before {
    content: "";
    position: absolute;
    left: 0.8em;
    top: 50%;
    width: 7px;
    height: 7px;
    transform: translateY(-50%);
    background-color: $orange;
    border-radius: 50%;
  }
}

a.alert-item:hover {
  background-color: $tab-subitem-hover !important;
}

.alert-content {
  font-size: 1rem;
  padding: 0.5em 1em !important;

  > .content {
    color: $dark-grey;
    font-size: 0.9em;
  }
}

.alert-icon {
  display: inline-block;
  font-size: 1.4em;
  width: 40px;
  height: 40px;
  line-height: 36px;
  text-align: center;
  border-radius: 50%;
  margin: 5px 0;
  background-color: $blue-primary;
  color: $white;

  > i {
    color: $white !important;
    font-size: 0.8em !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  &.warning {
    background-color: $orange;
  }
}

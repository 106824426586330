.add-comment,
.user-comment {
  position: relative;
  margin-bottom: 1rem;
  font-size: 1.05rem;

  > .user-icon {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
  }

  > .content {
    background-color: $silver;
    border-radius: 5px;
    padding: 0.8rem 1rem 0.8rem 1.8rem;
    margin-left: 2rem;
    display: flex;
    flex-wrap: wrap;

    > .author {
      margin-right: auto;
      color: black;
    }

    > .metadata {
      font-size: 0.9em;
      color: $dark-grey;
    }

    > .text {
      width: 100%;
      margin-top: 0.5em;
      color: $dark-grey;
    }

    > .delete-comment {
      position: absolute;
      top: -0.4rem;
      right: -0.7rem;
      cursor: pointer;
      color: $dark-grey;
    }
  }
}

.add-comment {
  > .user-icon {
    width: 44px;
    height: 44px;
    line-height: 44px;
    font-size: 1.5em;
  }

  .post-action {
    background-color: $white;
    padding: 0.2em 0.8em;
    font-size: 0.85em;
    display: flex;
    align-items: center;
    border-top-right-radius: 0.285rem;
    border-bottom-right-radius: 0.285rem;
  }

  > .content {
    background-color: $blue-primary;
    padding: 0.5rem 1rem 0.5rem 1.8rem;

    .ui.input {
      width: 100%;

      input {
        flex: initial;
        width: 100%;
      }

      input:focus {
        border-color: $white !important;
      }

      &.disabled {
        opacity: 1;
        input,
        .post-action {
          background-color: $silver;
          pointer-events: none;
        }
      }
    }
  }
}

@media screen and (min-width: $desktop-wide) {
  html {
    margin-right: calc(100% - 100vw); /* prevent scrollbar content jumping */
  }
}

html {
  min-height: 100vh;
  height: initial;
}

body,
#root {
  background-color: $background-color;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  line-height: 1.2em;
}

#root {
  display: flex;
  flex-direction: column;
}

p {
  line-height: 1.2em;
}

a {
  color: $blue-primary;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    color: $blue-secondary;
  }
}

.app-page-content {
  flex-shrink: 0;

  &.middle {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.environment-banner {
  background-color: $red;
  border: 1px solid white;
  color: white;
  display: block;
  font-size: 20px;
  font-weight: 700;
  height: 36px;
  line-height: 30px;
  opacity: 0.6;
  position: fixed;
  left: -130px;
  text-align: center;
  bottom: 50px;
  transform: rotate(45deg);
  width: 400px;
  z-index: 10000;
  pointer-events: none;
}

.ui.button.link-button {
  background: none;
  color: $blue-primary;

  &:hover {
    color: $blue-secondary;
  }
}

.load-more {
  width: 100%;
  margin-right: 0 !important;

  > .ui.button {
    display: block;
    margin: 1.5em auto 1em auto;

    &.disabled,
    &:disabled {
      opacity: 1 !important;
    }
  }
}

// semantic overrides

.ui.button {
  font-weight: 500;
}

.ui.basic.button {
  font-weight: bold;
  color: $blue-primary !important;
  &:hover {
    color: $blue-secondary !important;
  }
}

.ui.basic.label {
  border: none;
  font-weight: normal;
  > a {
    opacity: 1;
    font-weight: normal;
    text-decoration: underline;
    &:hover {
      color: $blue-secondary;
    }
  }
}

.ui.form input[type="text"],
.ui.form input[type="password"],
.ui.selection.dropdown {
  line-height: 1.2em;
}

.ui.checkbox label {
  line-height: 1.4em;
}

.ui.form input[type="text"],
.ui.form input[type="password"],
.ui.form textarea,
.ui.selection.dropdown,
.ui.checkbox label:before,
.ui.checkbox input:checked ~ label:before,
.ui.form .dropzone {
  border: $field-border;
}

.ui.form input[type="text"]:focus,
.ui.form input[type="password"]:focus,
.ui.form textarea:focus,
.ui.selection.dropdown:hover,
.ui.checkbox:hover label:before,
.ui.checkbox input:focus ~ label:before,
.ui.checkbox input:checked:focus ~ label:before,
.ui.form .dropzone:focus {
  border: $field-border-focus;
}

.ui.toggle.checkbox input {
  &:checked ~ .box:before,
  &:checked ~ label:before,
  &:focus:checked ~ .box:before,
  &:focus:checked ~ label:before {
    background-color: $toggle-colour !important;
  }
}

.ui.selection.dropdown {
  padding-bottom: 0;
  min-height: 2.6em;
}

.ui.multiple.dropdown > .text {
  margin-bottom: 0;
}

.ui.multiple.dropdown > .label {
  background-color: $blue-primary;
  color: $white;
  font-weight: normal;
  &.active {
    color: $silver;
  }
  &:hover {
    background-color: $blue-secondary;
  }
}

.ui.dividing.header {
  padding-bottom: 0.5em;
  margin-right: 2em;
  border-bottom: 2px solid $blue-secondary;
  display: inline-block;
}

.ui.segment,
.ui.segments {
  border: 1px solid $silver;
  border-radius: 0;
  box-shadow: 2px 2px 5px 0px $silver;
}

.ui.popup > .content {
  white-space: pre-line;
}

.ui.popup.inverted,
.ui.popup.inverted:before {
  background: $blue-tertiary !important;
}

.ui.dropdown.active > .default.text {
  opacity: 0;
}

.ui.modal > .content {
  line-height: 1.2em;
}

// cinnamon overrides

.cin.page-content > .column {
  height: 100%;
}

@media screen and (max-width: $tablet-max) {
  .cin.page-content > .column:not(.compact) {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1em;
    padding-right: 1em;
  }
}

.cin.page-loader {
  align-items: center;
  padding-top: 0;
}

.cin.tile-grid {
  margin: 0 -0.5em;
}

.cin.tile-grid.padded {
  margin: 0 -1em;
}

.cin.tile-grid > .cell.padded {
  padding: 0.5em 1em;
}

.cin.action-bar {
  flex-wrap: wrap;
  margin-bottom: -0.5em;

  > .action-bar-item {
    margin-bottom: 0.5em;
  }

  > .action-bar-item:last-child {
    > .ui.button,
    > .action-menu > .ui.button {
      margin-right: 0;
    }
  }

  .ui.form .fields {
    flex-wrap: wrap;
    margin-bottom: 0;

    > .field {
      margin-bottom: 0;
      @media screen and (max-width: $tablet-max) {
        margin-bottom: 0.5em;
      }
    }
  }

  &.central {
    align-items: center;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.cin.side-column {
  > .content {
    max-width: calc(100% - 15em);
  }
}

.cin.side-column.collapsed {
  > .column {
    display: none;
  }

  > .content {
    padding-left: 0;
    max-width: 100%;
  }
}

.cin.side-panel {
  padding: 1rem;
  z-index: 999;
  max-width: 100%;

  .cin.loadable > .ui.dimmer {
    background-color: $white;
  }

  > .dimmable {
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .ui.header,
  .icon.close-panel {
    font-size: 1.2em;
  }

  .icon.close-panel {
    top: 0.5em;
    right: 0.5em;
    padding: 0.4em;
    background-color: rgba($white, 0.6) !important;
  }

  .yes-no-any-radio,
  .cascading-radio-group {
    > .field {
      padding-bottom: 0 !important;
    }
  }
}

.cin.breadcrumb {
  margin-bottom: 1.5rem;

  .ui.breadcrumb {
    font-size: 1.05rem;
    font-weight: bold;
    i {
      font-size: 1.2rem;
    }
    a {
      color: $text-color;
      &:hover {
        color: $blue-secondary;
      }
    }
  }
}

.cin.form-clearer {
  display: inline-block;

  .ui.button {
    margin-bottom: 0;
    padding: 2px 0.5em;
    color: $grey !important;
  }

  &.clear-all > .ui.button {
    padding: 0 1em;

    &:before {
      content: "Clear All";
    }
    & > i {
      display: none;
    }
  }
}

.cin.notification-stack {
  z-index: 5000;
}

.cin.action-menu > .ui.vertical.menu {
  .item {
    display: flex;
    > i.icon {
      margin-left: 0;
      margin-right: 0.75em;
    }
  }
}

.cin.data-list-item > dt {
  margin-bottom: 0.25em;
}

.cin.number-formatter.negative {
  color: $red;
}

.max-page-size {
  max-width: 98% !important;
}

.faded {
  opacity: 0.5;
}

.whitespace-pre {
  white-space: pre;
}

.hidden {
  visibility: hidden;
}

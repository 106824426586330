.system-page-content {
  background-color: $white;
  min-height: 100vh;
  height: 100%;

  &:not(.compact) > .ui.grid > .column:first-child {
    @media screen and (min-width: 1200px) {
      > .header {
        margin-bottom: 9em;
      }
    }

    .content .ui.form input {
      padding: 1em 1.4em;
      font-size: 1.1em;
    }
  }

  &.compact > .ui.grid > .column:first-child {
    .content .ui.button {
      margin-top: 1.4em;
      margin-bottom: 1.6em;
    }
  }

  > .ui.grid {
    min-height: 100vh;
    height: 100%;
    margin: 0;

    > .column:first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 100%;

      > .header {
        width: 32em;
        max-width: 100%;
        margin-top: 5em;
        margin-bottom: 4em;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;

        .image.logo {
          width: 14em;
          margin-right: 1em;
        }

        .image.logo + * {
          margin-left: auto;
          margin-top: 0.5em;
        }

        @media screen and (max-width: $mobile-small) {
          .image.logo {
            margin-right: 0;
          }

          .image.logo + * {
            min-width: 12em;
            margin-left: 2em;
            margin-right: 2em;
            margin-top: 1em;
          }
        }
      }

      > .content {
        width: 32em;
        max-width: 100%;
        color: $dark-grey;

        .ui.header:not(.attached) {
          margin-bottom: 2rem;

          .sub.header {
            font-size: 0.9rem;
            margin: 0.7rem 0;
            color: $dark-grey;
          }
        }

        h1.ui.header {
          font-size: 2.4rem;
        }

        .ui.icon.input > i.icon {
          opacity: 0.3;
        }

        .ui.button {
          margin-top: 1.8em;
          margin-bottom: 2em;
          margin-right: 0;

          &.large {
            padding: 1.1em 1.5em;
            font-size: 1.2rem;
          }
        }

        .ui.message {
          width: 100%;
        }

        .split-buttons {
          display: flex;
          align-items: center;

          > *:not(:last-child) {
            margin-right: 0.5em;
          }

          > a {
            text-align: center;
          }
        }
      }

      > .footer {
        width: 28rem;
        max-width: 100%;
        color: $dark-grey;
        margin-top: 2em;
        font-size: 0.9rem;
      }
    }

    & .column.splash {
      padding: 0;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 1199px) {
        display: none !important;
      }

      > .tagline {
        position: relative;
        flex-basis: 50%;
        display: flex;

        > .image {
          flex: 1;
          background-size: cover;
          background-position: center;
        }

        > .caption {
          flex: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 2.2rem;
          font-weight: $semibold-weight;
          line-height: 1.25em;
          padding: 2.5em;
        }

        &.upper {
          color: $white;
          background-color: $blue-primary;

          > .image {
            background-image: url("~assets/backgrounds/login-dialogue.jpg");
          }

          > .caption {
            min-width: 22rem;
            text-align: center;
            background-image: url("~assets/logos/peacelily-blue.png");
            background-position: 50% 45%;
            background-size: 70% auto;
            background-repeat: no-repeat;
          }
        }

        &.lower {
          color: $white;
          background-color: $claret-primary;

          > .image {
            background-image: url("~assets/backgrounds/login-experts.jpg");
            background-position: 70% 50%;
          }

          > .caption {
            min-width: 38.5rem;
          }
        }
      }
    }
  }
}

.auth-recovery-codes {
  ul {
    list-style-type: none;
    margin-top: 0.25rem;
    margin-bottom: 0;
    padding: 0;

    li {
      font-family: monospace;
      font-size: 1.2em;
      margin-bottom: 0.25rem;
    }
  }
}

.auth-setup-2fa {
  .auth-content-column {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .auth-or-text {
    padding: 1em 0;
    color: $grey;
    font-size: 16px;
  }
}

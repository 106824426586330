.simple-page-content {
  background-color: $white;
  min-height: 100vh;
  height: 100%;
  font-size: 1.2rem;

  > .header {
    padding: 3rem 8rem;
    display: flex;
    justify-content: center;

    @media screen and (min-width: $desktop-min) {
      justify-content: flex-end;
    }

    .image.logo {
      width: 24rem;
    }
  }

  > .cin.page-content {
    padding: 1rem 2rem;
  }
}

.data-retention {
  .consent-options > .option {
    margin-top: 2em;
    > a {
      display: inline-block;
      font-size: 1.15em;
      line-height: 1.15;
    }
  }

  .fowp-options > .option {
    margin-top: 2em;
    margin-bottom: 2em;
    margin-right: -1em;
    > a {
      display: inline-block;
    }
  }

  .fowp-options > .ui.header {
    margin-top: 1.75em;
    margin-bottom: 0.75em !important;
  }
}

.content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0.75rem;

  &:not(:first-child) {
    margin-top: 1.5rem;
  }

  > .ui.header {
    margin-bottom: 0;
  }
}

.content-panel {
  &.ui.segment {
    padding: 1.5em 2em;
    background: $white;
  }

  @media screen and (max-width: $tablet-max) {
    padding: 1.5em 1em;
  }

  &.split {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1em 0em;
  }

  > .left-content,
  > .right-content {
    width: calc(50% - 0.5rem);

    @media screen and (max-width: $tablet-max) {
      width: calc(50% - 0.5rem);
    }

    @media screen and (max-width: $mobile-max) {
      width: 100%;
      &:is(.right-content) {
        margin-top: 1rem;
      }
    }
  }

  &.collapse-early {
    @media screen and (max-width: $tablet-max) {
      > .left-content,
      > .right-content {
        width: 100%;
        &:is(.right-content) {
          margin-top: 1rem;
        }
      }
    }
  }

  .cin.loadable > .ui.dimmer {
    background-color: $white;
  }
}

.html-content {
  margin-bottom: 1em;

  &:last-child {
    margin-bottom: 0;
  }

  h3 {
    color: $blue-primary;
  }

  .ql-align-center {
    text-align: center;
  }

  .ql-align-right {
    text-align: right;
  }
}

.scrollable-content {
  overflow-y: auto;
  scrollbar-color: $silver $background-color;
  min-height: 90vh;

  &.outside {
    padding-right: 1rem;
    margin-right: -1rem;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  &::-webkit-scrollbar-thumb {
    background: $silver;
    &:hover {
      background: $light-grey;
    }
  }
}

.scrollable-table {
  > .outer-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    > .inner-scroll {
      height: 1px;
    }
  }

  > .table-scroll {
    overflow-x: scroll;
    overflow-y: visible;
  }
}

.filter-panel {
  padding: 1em 1em 0.7em 1em !important;

  @media screen and (min-width: $desktop-min) {
    &.padded {
      padding: 1em 2em 0.7em 2em !important;
    }
  }

  .action-bar {
    margin-top: -0.5em;
    align-items: center;

    > .action-bar-item {
      margin-top: 0.5em;
      margin-bottom: 0.5em;

      &:not(:last-child) {
        margin-right: 1em;
      }

      .ui.form {
        margin-top: -0.5em;
        margin-bottom: -0.5em;
      }

      .ui.label {
        padding: 0 2em 0 0;
        margin-top: 1em;
      }
    }
  }

  .cin.breadcrumb {
    margin-bottom: 1rem;

    .ui.breadcrumb {
      font-size: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.message-box.ui.segment {
  margin-top: 1em;
  border-color: $blue-primary;
  background-color: $pale-blue;
  border-radius: 4px;

  > header {
    color: $blue-primary;
    @include DefineFont(16px, $semibold-weight);
    margin-bottom: 0.25rem;
    display: flex;
    align-items: center;
  }
}

.team-members-display {
  display: flex;
  flex-wrap: wrap;
  margin: -0.5em 0;
  .team-member {
    margin: 0.5em 0;
    width: calc(50% - 0.5rem);
    max-width: calc(50% - 0.5rem);
    .user-info {
      flex-grow: 1;
      overflow: hidden;
      > * {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.quick-links-display {
  $max-height: 40px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: $max-height;
  grid-row-gap: 1rem;

  @media screen and (max-width: $mobile-max) {
    grid-template-columns: 1fr;
    grid-template-rows: $max-height $max-height;
    > * {
      justify-content: left;
      width: 100%;
    }
  }

  > .quick-link {
    max-width: 100%;
    display: inline-flex;
    padding-right: 2em;

    img {
      height: 100%;
      max-width: 100%;
    }

    &.disabled img {
      filter: grayscale(1);
      opacity: 0.6;
    }
  }

  .one-note-hidden {
    grid-template-rows: 40px !important;
  }
}

.text-field-display-list.cin.data-list {
  .cin.data-list-item {
    display: flex;
    flex-direction: column;
    margin-bottom: 1em;
    padding: 0;
    > label {
      @include DefineFont(12px, $semibold-weight);
    }
    > .content {
      white-space: pre-wrap;
    }
    > dd {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.content-panel.split.no-collapse {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0;
  > * {
    width: calc(50% - 1em);
    position: relative;
    margin-top: 0;
  }
  @media screen and (max-width: 639px) {
    .left-content,
    .right-content {
      width: calc(50% - 1em);
    }
  }
}

.cin.side-panel .tabs-panel {
  .cin.nav-menu-item {
    padding: 0 !important;
    margin: 0.928571em 1.14286em 0.928571em 2px;

    &.active {
      position: relative;
      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: $blue-primary !important;
        top: initial;
        bottom: -4px;
      }
    }
  }
}

.expand-box {
  cursor: pointer;
  > header {
    display: flex;
    align-items: center;
    > .header-content {
      flex-grow: 1;
      flex-shrink: 1;
      margin-right: 1em;
    }
    > .expand {
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  > footer {
    display: none;
    margin-top: 1em;
    cursor: default;
  }

  &.expanded > footer {
    display: block;
  }
}

.message-panel.ui.message {
  display: flex;
  align-items: center;
  &.orange {
    background-color: $warning-red-light;
    color: $orange;
  }
  &.blue {
    background-color: $pale-blue;
    color: $blue-primary;
  }
  .message-content {
    color: $text-color;
    margin-left: 1em;
    flex-grow: 1;
  }
}

.content-line {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  &.break {
    flex-direction: column;
    .content-item:last-child {
      margin-top: 0.25em;
    }
  }
  > .fixed-item {
    flex-shrink: 0;
  }
  > .single-text-line-item {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  > .two-text-lines-item {
    flex-grow: 1;
    flex-shrink: 1;
    @include LineClamp(2);
  }
}

.column-grid {
  display: grid;
  grid-template-columns: 1fr; // overridden at component level
  grid-template-rows: auto;
  grid-auto-rows: auto;
  grid-gap: 1em; // overridden at component level

  .column-grid-item {
    position: relative;
    grid-column: auto / span 1; // overridden at component level
  }
}

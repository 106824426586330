.pill {
  display: inline-flex;
  background-color: $white;
  border: solid 1px $blue;
  padding: 0.2em 0.5em;
  border-radius: 12px;
  color: $blue;
  height: 38px;
  align-items: center;

  @media screen and (max-width: $tablet-max) {
    margin-bottom: 0.25em;
  }

  > .icon {
    cursor: pointer;
    margin-left: 0.5em;
    margin-right: 0;
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
  }

  > .cin.form-clearer {
    align-self: auto;

    .ui.button {
      margin-right: 0;
      padding-right: 0;
    }
  }

  & + .pill {
    margin-left: 0.5em;
  }
}

.filter-bar {
  justify-content: flex-end;

  .title {
    @include DefineFont(16px, $bold-weight);
    margin: 0 0 0.3rem 0;
    display: flex;

    .info,
    .subtitle {
      @include DefineFont(12px, $regular-weight);
      margin-left: 1em;
      display: flex;
      line-height: 16px;
      align-items: flex-end;
    }
  }

  .cin.action-bar-item {
    margin-bottom: 0;

    .fields {
      margin-bottom: 0.25em;
    }

    .cascading-radio-group.field .ui.checkbox {
      margin-bottom: -0.3em;
      margin-top: 0.8em;
      margin-right: 1em !important;
    }
  }

  .search-filter.cin.action-bar-item {
    margin-right: auto;
  }

  .applied-filters.cin.action-bar-item {
    margin: 0 2em;

    .title {
      @include DefineFont(12px, $regular-weight);
      margin-bottom: 0;
      position: relative;
      top: 4px;
    }

    .pill-wrapper {
      margin-top: 8px;

      .no-filters {
        height: 38px;
        line-height: 38px;
        color: $grey-text;
      }

      .many-filters {
        color: $blue;
        margin-left: 1em;
      }
    }

    .cin.form-clearer .ui.button {
      color: $blue !important;
    }
  }

  .bar-actions {
    align-self: center;
  }
}

@media screen and (max-width: $tablet-max) {
  .first-filter-action {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}

.advanced-filters {
  .field.column {
    display: flex;
    flex-direction: column;
  }

  .ui.form .field:last-child,
  .ui.form .fields:last-child .field {
    margin-bottom: 0;
    padding-bottom: 1em;
  }

  .cin.date-range {
    .react-datepicker-wrapper,
    .react-datepicker__input-container {
      display: block;
    }

    > .tight-right {
      width: 50%;
      padding-right: 0.5em;
    }

    > .tight-left {
      width: 50%;
      padding-left: 0.5em;
    }
  }
}

.value-range {
  align-items: flex-end;

  .field:first-child {
    .ui.input input,
    .ui.form .field .ui.input input {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }

  .field:last-child {
    padding-bottom: 0 !important;
    .ui.input input,
    .ui.form .field .ui.input input {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

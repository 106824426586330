.profile-background {
  overflow: hidden;
  width: 100%;
  height: 200px;

  > .profile-background-image {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    background-color: $profile-default-background-color;
  }
}

.profile-image {
  position: absolute;
  top: 80px;
  left: 40px;
  width: 220px;
  height: 220px;

  > img {
    width: 100%;
    height: 100%;
    border: 6px solid $white;
    background-color: $white;
  }

  @media screen and (max-width: $mobile-max) {
    width: 180px;
    height: 180px;
    top: 10px;
    left: 20px;
  }
}

.profile-section {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  .icon-stack {
    margin-bottom: 1.5em;
    margin-right: 6rem;
    max-width: 50%;
  }

  .social-media-link {
    margin-bottom: 0.5em;
  }
}

.profile-name {
  display: flex;
  align-items: center;
  margin-right: 2rem;
  margin-bottom: 0.5em;

  > .display-name.ui.header {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &.disabled {
    opacity: 1;
    > .display-name {
      opacity: 0.5;
    }
  }
}

.profile-job-title {
  width: 100%;
  margin-bottom: 1em;
  &.disabled > .title {
    opacity: 0.5;
  }
}

.profile-themes {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5em;

  .profile-theme {
    margin-right: 1em;
    margin-bottom: 1em;
  }
}

.profile-theme {
  padding: 0.5em;
  border-left: 3px solid $blue-primary;
  box-shadow: 2px 2px 5px 0px $silver;
  &.disabled {
    opacity: 0.5;
    filter: grayscale(1);
  }
}

.profile-biography {
  > .bio-text {
    white-space: pre-wrap;
  }

  > .ui.header {
    margin-bottom: 0.5rem;
  }

  &.disabled {
    > .bio-text {
      opacity: 0.5;
    }
  }
}

.profile-content.cin.side-column {
  padding: 2em;

  @media screen and (max-width: $mobile-max) {
    padding-left: 1em;
    padding-right: 1em;
  }

  > .column {
    padding-top: 90px;
    width: 260px;
    min-width: 220px;

    .nav-menu-item:not(.secondary) {
      &.active {
        > .content > span {
          position: relative;
          display: inline;
          &::after {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $blue-primary;
            bottom: -4px;
            left: 0;
          }
        }
      }
    }
  }
}

.profile.public {
  .profile-section {
    padding-left: 3em;
    padding-right: 3em;

    @media screen and (max-width: $mobile-max) {
      padding-left: 1em;
      padding-right: 1em;
    }
  }

  .profile-image + .profile-section {
    margin-top: 1.5em;

    @media screen and (min-width: $tablet-min) {
      padding-left: 290px;
      min-height: 100px;
    }
  }
}

.profile-header-edit-link.ui.segment {
  position: absolute;
  top: 0;
  right: 0;
  margin: 2em;

  @media screen and (max-width: $mobile-max) {
    margin: 1em;
  }
}

.edit-profile-details {
  display: flex;
  position: relative;
  margin-bottom: 0.5em;
}

.edit-profile-image {
  &:hover {
    &::before {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: $calendar-summary-grey;
      background-image: url("~assets/icons/add-image.svg");
      background-repeat: no-repeat;
      background-position: calc(50% + 7px) calc(50% + 7px);
      border-radius: 500rem;
      z-index: 5;
      opacity: 0.6;
      cursor: pointer;
    }
  }
}

.privacy-toggle {
  margin-left: 1em;
  $width: 2.5rem;
  $height: 1.1rem;
  width: $width;
  height: $height;
  align-self: center;
  > .ui.fitted.toggle.checkbox {
    width: $width;
    height: $height;
    min-height: 0;
    input {
      width: $width;
      height: $height;
      &:checked ~ label:after {
        left: 1.4rem;
      }
    }
    label {
      width: $width;
      height: $height;
      min-height: 0;
      &:before {
        width: $width;
        height: $height;
      }
      &:after {
        height: $height;
        width: $height;
      }
    }
  }
}

.profile.privacy {
  .profile-section:first-child {
    margin-bottom: 1em;
  }
  .social-media-link {
    display: flex;
    margin-right: 2rem;
    .privacy-toggle {
      display: flex;
      align-items: center;
      margin-top: 6px;
    }
  }
  .profile-themes .privacy-toggle {
    display: flex;
    align-items: center;
    margin-bottom: 1em;
  }
  .icon-stack .icon-text {
    > .privacy-toggle {
      display: flex;
      flex-direction: row-reverse;
      flex-grow: 1;
    }
  }
  .profile-biography .ui.header {
    display: flex;
  }
}

.confirm-privacy.message-box.ui.segment {
  border-color: $warning-red;
  background-color: $warning-red-light;
  > header {
    color: $warning-red;
  }
}

.sub-themes {
  background: $white;
  margin: 0;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  border-radius: 20px;
  .icon {
    height: auto;
    padding-right: 0.3em;
  }
  &.disabled {
    opacity: 0.5;
    filter: grayscale(1);
    > .icon {
      opacity: 0.5;
      filter: grayscale(1);
    }
  }
}

// styling for attending participant slideout
.attending-participant {
  display: flex;
  align-items: center;

  > .name {
    margin-left: 1em;
    flex: 0 0 auto;
    min-width: 10em;
    width: 15%;
    @include DefineFont(14px, $semibold-weight);
  }

  .attending-info {
    .representing {
      text-transform: uppercase;
    }
  }

  &.selectable {
    cursor: pointer;

    &:hover {
      background-color: $hover-grey;
    }
  }

  > .attendance-category {
    margin-left: auto;
  }

  @media screen and (max-width: $mobile-max) {
    flex-wrap: wrap;
  }
}

.organisation-dropdown-item {
  .sub-details {
    @include DefineFont(12px);
  }
}

.attending-participant-profile.cin.side-panel {
  padding: 0 0 1em 0;

  > .dimmable {
    padding: 0;

    > .button.close-panel {
      z-index: 1000;
      top: 1em;
      right: 1em;
    }

    > .cin.loadable {
      height: 100%;
    }
  }
}

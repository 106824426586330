.cascading-radio-group.field {
  > .field {
    display: flex;
    flex-wrap: wrap;

    > label {
      width: 100%;
      margin-bottom: 0;
    }

    > .ui.checkbox {
      margin-right: 2em !important;
      padding-bottom: 0.5em !important;
    }

    > div:not(.checkbox) {
      width: 100%;
    }
  }
  &.required > .field > label::after {
    margin: -0.2em 0em 0em 0.2em;
    content: "*";
    color: $red;
  }
}

.organisation-typeahead.field {
  display: inline-flex;
  align-items: flex-end;
  &.fluid {
    display: flex;
  }
  > .field {
    flex-grow: 1;
    margin-bottom: 0;
  }
  .advanced-search-button {
    min-width: 36px;
    padding: 0 !important;
    margin-right: 0;
    margin-left: 0.25em;
  }
  &.attached-button {
    .ui.dropdown {
      border-right: none;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    .advanced-search-button {
      margin-left: 0;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.ui.form .field + .custom-errors.field > div:first-child {
  margin-top: -1em;
}

.cin.time-select,
.time-select.field {
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    display: block;
  }
  .react-datepicker__time-list {
    padding: 0 !important;
  }
}

.cin.base-picker {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      .ui.disabled {
        opacity: 1;
        input {
          background-color: $silver;
        }
      }
    }
  }
}

.html-editor.field {
  > .quill {
    background-color: $white;
  }

  .ql-container {
    border: $field-border !important;
    font-size: 1rem;
    font-family: $font-family;
  }

  .ql-toolbar {
    border-top: $field-border !important;
    border-left: $field-border !important;
    border-right: $field-border !important;
    border-bottom: none !important;
    font-family: $font-family;
  }

  .ql-editor {
    max-height: calc(100vh - 260px);

    a {
      color: $blue-primary;
    }

    p,
    ul,
    ol {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-top: calc(2rem - 0.142857em);
      margin-bottom: 1rem;
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.71rem;
    }

    h3 {
      font-size: 1.28rem;
      color: $blue-primary;
    }

    h4 {
      font-size: 1.07rem;
    }
  }

  .ql-header .ql-picker-options {
    padding: 0.5rem 0;

    > .ql-picker-item {
      padding: 0.5rem;
    }

    > .ql-picker-item:hover {
      color: initial;
      background-color: $silver;
    }

    > .ql-picker-item[data-value="1"]::before {
      font-size: 2rem;
      font-weight: bold;
    }

    > .ql-picker-item[data-value="2"]::before {
      font-size: 1.71rem;
      font-weight: bold;
    }

    > .ql-picker-item[data-value="3"]::before {
      font-size: 1.28rem;
      font-weight: bold;
      color: $blue-secondary;
    }

    > .ql-picker-item[data-value="4"]::before {
      font-size: 1.07rem;
      font-weight: bold;
    }
  }

  .ql-tooltip {
    background-color: $silver;
    transform: translateY(-150%);
  }

  .ql-tooltip.ql-editing input {
    width: initial;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="0.75em"]::before {
    content: "Small";
    font-size: 0.75em !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1em"]::before {
    content: "Normal";
    font-size: 1em !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="1.5em"]::before {
    content: "Large";
    font-size: 1.5em !important;
  }

  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="2.5em"]::before {
    content: "Huge";
    font-size: 2.5em !important;
  }
}

.ui.form .dropzone {
  background-color: $white;
  &:focus {
    outline: none;
  }
}

.removable-array-row {
  display: grid;
  grid-gap: 1rem 1rem;
  grid-template-columns: 1fr 1fr auto;

  &.one-col {
    grid-template-columns: 1fr auto;
  }
  &.two-col {
    grid-template-columns: 1fr 1fr auto;
  }
  &.three-col {
    grid-template-columns: 1fr 1fr 1fr auto;
  }

  @media screen and (max-width: 500px) {
    display: block;
    & > .field {
      margin-bottom: 0.5em !important;
    }
    & > a {
      margin-bottom: 1em;
      width: 100%;
      display: block;
      text-align: center;
      padding: 0 !important;
    }
  }
}

.inline-radio-list {
  > .field.inline {
    display: flex;
    flex-wrap: wrap;

    > label {
      width: 100% !important;
      margin-bottom: 1em !important;
    }
    .ui.radio.checkbox {
      margin-left: 0 !important;
      margin-right: 2em !important;
      margin-bottom: 0 !important;
    }
  }
}

.ui.form .field.disabled {
  opacity: 1;
  > label {
    opacity: 1;
  }
  > .ui.disabled {
    opacity: 1;
    input {
      background-color: $silver;
    }
  }
  > .ui.dropdown.disabled,
  textarea:disabled {
    background-color: $silver;
  }
}

.outside-multiselect {
  .tags {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0.5em 0;
    padding: 0;
    > .tag {
      margin: 0 1em 0.25em 0;
      color: $blue-primary;
      border: solid 1px $blue-primary;
      border-radius: 4px;
      padding: 0.25em 0 0.25em 0.5em;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &.disabled {
    .tag > .icon {
      width: 0;
      opacity: 0;
    }
  }
}

.attending-available-dates .available-date {
  cursor: pointer;
  user-select: none;
  color: $text-color;
  .icon {
    color: $blue-primary;
  }
  &:not(.disabled):hover {
    box-shadow: inset 0px 0px 2px 0px rgba($blue-primary, 0.75);
    border-radius: 4px;
  }
  &.disabled {
    cursor: default;
    opacity: 1;
    color: $text-color;
    .icon {
      color: $grey;
    }
  }
}

.relative-date-picker {
  > :last-child {
    padding-top: 2.2rem;
    .ui.radio.checkbox {
      margin: 1rem 0.5em 1em 1em;
    }
  }
}

.colour-picker.field {
  .ui.input > .icon {
    position: absolute;
    top: 0.65em;
    right: 0.65em;
    pointer-events: none;
  }
}

.clearable-input.ui.input {
  > .cin.form-clearer {
    position: absolute;
    display: flex;
    right: 0;
    top: 0;
    bottom: 0;
    .ui.button {
      color: initial !important;
      opacity: 0.8 !important;
      padding: 0 0.75em;
      &:hover {
        background: none !important;
        opacity: 1 !important;
      }
    }
  }
}

.ui.form .data-list-fields {
  margin: -0.5rem -0.5rem 0.5rem -0.5rem;
}

.auth-code-box {
  .code-container {
    width: 100%;
    > .vi {
      opacity: 0;
    }
    .code-character {
      border: solid 1px $field-border-colour;
      border-radius: 4px;
      font-size: 24px;
      color: $text-color;
    }
    .code-character-selected {
      outline: 2px solid $blue-primary;
      position: relative;
    }
    .code-character-inactive {
      background-color: $silver;
    }

    .code-character-selected:not(.code-character-filled)::after {
      content: "";
      width: 1px;
      height: 20px;
      background-color: $text-color;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      animation: blink 1s infinite step-end;
    }

    @keyframes blink {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
    }
  }
}

.file-icon {
  width: 24px;
  height: 24px;
}

.user-icon {
  display: inline-block;
  font-size: 1.4em;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: $tab-active;
  color: $blue-primary;
}

.icon-text {
  display: flex;
  align-items: center;
  &.disabled {
    & > img,
    & > .icon,
    & > span {
      opacity: 0.5;
    }
  }
  & > img,
  & > .icon {
    height: 16px;
    width: 16px;
    margin-right: 0.75rem;
    line-height: 1em;
  }
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0.5em;
  }
}

.icon-text-smaller-margin {
  display: flex;
  align-items: center;
  &.disabled {
    & > img,
    & > .icon,
    & > span {
      opacity: 0.5;
    }
  }
  & > img,
  & > .icon {
    height: 16px;
    width: 16px;
    margin-right: 0.35rem;
    line-height: 1em;
  }
  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 0.5em;
  }
}

.icon-stack {
  > .ui.header {
    margin-bottom: 0.5rem;
  }
  > .icon-text {
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > .icon-text:last-child {
      margin-bottom: 0.5rem;
    }
  }
}

.user-icon-profile-details {
  display: flex;

  .user-icon {
    margin-right: 1rem;
  }
  .user-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.chevron-icon {
  &.flipped {
    transform: rotate(180deg);
  }
}

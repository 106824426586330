.programme {
  font-size: 1rem;

  .programme-date {
    display: flex;
    border-bottom: $programme-primary-border;
    padding: 2em 0 1.5em 0;

    &:last-child {
      border-bottom: none;
    }

    > .date-label {
      align-self: flex-start;
      min-width: 4.2em;
      margin-right: 0.7em;
      color: $blue-tertiary;
      text-align: right;
      border-radius: 5px;
      padding-right: 0.5em;
      padding-top: 0.5em;
      padding-bottom: 0.5em;

      > * {
        display: block;
        font-size: 1.2em;
        font-weight: bold;
        margin-bottom: 1em;
      }

      > .day-of-month {
        font-size: 1.8em;
        margin-bottom: 0.7em;
      }

      > .month {
        font-weight: normal;
      }

      > .icon.edit {
        margin-bottom: 0;
        margin-right: 0;
        display: inline-block;
        font-size: 1em;
        color: $blue-tertiary;
      }
    }

    &.editable > .date-label:hover {
      background: $programme-content-hover;
      cursor: pointer;
    }

    > .date-content {
      flex-grow: 1;
      padding-left: 2em;
      border-left: $programme-primary-border;
    }
  }

  .programme-info {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .programme-activity {
    display: flex;
    align-items: baseline;
    padding-bottom: 0.5em;
    margin-bottom: 0.5em;
    border-bottom: $programme-secondary-border;

    &:last-child {
      margin-bottom: 0;
      border-bottom: none;
    }

    > .activity-time {
      width: 8em;
      min-width: 8em;
      color: $blue-tertiary;
      font-weight: bold;

      .ui.form {
        padding-right: 1.5em;
      }

      .ui.form .field > label {
        font-size: 0.85em;
        font-weight: normal;
      }
    }

    > .activity-content {
      flex-grow: 1;
    }
  }

  .programme-item,
  .live-session {
    padding: 0.5em 1em;
    border-radius: 5px;
    background: $programme-content-background;

    &:hover {
      background: $programme-content-hover;
    }

    &.upcoming:not(.draft):not(.placeholder) {
      background: $programme-upcoming-background;
      &:hover {
        background: $programme-upcoming-hover;
      }
    }

    &.draft,
    &.placeholder {
      background: $programme-content-hover;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23057cdc' stroke-width='2' stroke-dasharray='6' stroke-dashoffset='0' stroke-linecap='butt' /%3e%3c/svg%3e");
    }

    &.placeholder {
      color: $blue-primary;
      font-style: italic;
      font-weight: bold;
    }

    &.selectable {
      cursor: pointer;
    }
  }

  .live-session {
    a {
      font-size: 0.9em;
    }

    p {
      margin-bottom: 0.5rem;
      &:last-child {
        margin-bottom: 0;
      }
    }

    .ui.header {
      margin-top: 0;
      margin-bottom: 0.5em;
      line-height: 1.4285em;
      &:last-child {
        margin-bottom: 0;
      }

      > .sub.header {
        display: inline-block;
        margin-left: 0.2em;

        a {
          font-style: italic;
          padding-right: 0.2em;
          font-size: 1em;
        }
      }
    }
  }

  @media screen and (max-width: $tablet-max) {
    .programme-date {
      flex-wrap: wrap;

      > .date-label {
        text-align: left;
        margin-bottom: 1em;
        margin-right: 0;
        padding-left: 0.5em;

        > * {
          display: inline-block;
          margin-right: 1rem;
          margin-bottom: 0 !important;
        }
      }

      > .date-content {
        width: 100%;
        padding-left: 0;
        padding-top: 1em;
        border-left: none;
        border-top: $programme-primary-border;
      }
    }

    .programme-activity {
      padding-left: 0.5em;
    }
  }

  @media screen and (max-width: $mobile-max) {
    .programme-activity {
      flex-wrap: wrap;
      padding-bottom: 1em;

      > .activity-time {
        width: 100%;
        margin-bottom: 1em;

        .ui.form {
          padding-right: 0;
        }
      }
    }
  }
}

.participant-registration {
  .wizard-wrapper {
    display: flex;
    flex-direction: row;
    > .progress-wrapper {
      width: 20em;
      flex-shrink: 0;
      margin-right: 1em;
    }
    > :not(.progress-wrapper) {
      flex-grow: 1;
      flex-shrink: 1;
    }
    &.collapsed {
      flex-direction: column;
      > .progress-wrapper {
        width: 100%;
        margin-bottom: 2em;
        margin-right: 0;
      }
    }
  }

  .registration-info.ui.segment {
    margin-top: 0;
  }

  .registration-group {
    .group-header {
      color: $blue-primary;
      @include DefineFont(16px, $semibold-weight);
      margin-bottom: 0.5em;
    }

    .item-grid > .column-grid-item {
      display: flex;

      &.hide-item > :not(.edit-item) {
        opacity: 0.3;
        pointer-events: none;
      }

      .edit-item {
        & > .floating-button {
          margin-left: 0.5em;
        }
      }
    }

    .field {
      flex-grow: 1;
      margin-bottom: 0;
      min-width: 0;
      > .sub-header {
        display: inline-block;
        width: 100%;
        @include DefineFont(11px);
        margin-bottom: 0.25em;
      }
    }

    .cin.side-column.stacking {
      flex-direction: column;
      > .column,
      > .content {
        width: 100%;
        max-width: initial;
        padding: 0;
        margin-bottom: 1em;
      }
    }
  }

  .step-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &.centred {
      justify-content: center;
    }
  }

  .ui.button.nav-next {
    margin: auto;
    display: block;
    background-color: $green;
  }

  .registration-welcome {
    @include DefineFont(14px, $semibold-weight);
    .name {
      font-style: italic;
    }
  }

  .subtheme-selection {
    .ui.dropdown {
      max-width: 30em;
    }
  }

  .add-question {
    background-color: $pale-blue;
    margin: 0 -1em -1em -1em;
    padding: 0.5em 0;
    .add-question-button {
      margin-left: 1em;
    }
  }

  .additional-section.ui.segment {
    background-color: $pale-blue;
    .title {
      @include DefineFont(14px, $semibold-weight);
    }
  }

  .template-message.ui.message {
    display: block;
    padding-left: 1em;
    padding-right: 1em;
    > .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: calc(0.25em + 2px);
    }
    > .content {
      color: $text-color;
    }
  }

  .travel-info.ui.segment {
    margin: 0 0 1rem 0;
    .travel-contact {
      color: $blue-primary;
      @include DefineFont(16px, $semibold-weight);
    }
  }
}

.participant-registration-event-header {
  @include DefineFont(18px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  .event-name {
    margin-left: 0.5em;
    @include DefineFont(18px, $semibold-weight);
  }
  .registration-event-info {
    border: solid 1px $blue-primary;
    border-radius: 3px;
    background-color: $pale-blue;
    height: 26px;
    width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
}

.edit-registration-item {
  .weeks-selector {
    > .field > .ui.input {
      > input {
        border-right-color: $field-border-colour !important;
        border-top-right-radius: 0.28571429rem !important;
        border-bottom-right-radius: 0.28571429rem !important;
        margin-right: 0.2rem;
        border: 1px solid #6e6f81 !important;
      }
      > .ui.label {
        background-color: transparent;
        @include DefineFont(14px);
        color: $text-color;
      }
    }
  }
}

.registration-template-actions {
  > .menu.ui {
    min-height: 1px;
  }
}
